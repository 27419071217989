<template>
    <div class="indexPage" :class="show==1?'show-sb':''" id="zhiding" >
        <!-- 头部 -->
        <header class="header">
       
            <h1 class="logo">
                <a href="/indexPage"></a>
            </h1>
            <div class="header-main">
               
                <div v-if="nav.boole">
                <form action="" class="search-advanced-top" v-show="this.$route.name!='SearchPage'">
                    <Search :text="keyword" :texts="select" @childFn="keywordFn"></Search>
                </form>
            </div>
            </div>
            <div class="top-links clearfix">
                <div class="navLink fl">
                    <!-- <span></span> -->
                    <span v-if="nav.boole">定制服务</span>
                    <div class="plus_xiala">
                        <div class="plus_xiala_title">
                            <h5>PLUS-代写定制 · 全托管</h5>
                            <p>专属定制项目BP一站式服务，适用于全阶段项目团队。</p>
                        </div>
                        <h6 class="plus-xiala-subtitle">· 模板定制服务 ·</h6>
                        <div class="plus-xiala-list">
                            <div class="plus-xiala-item2">
                                <h5>PPT模板定制</h5>
                                <h6>网评、路演使用全授权</h6>
                                <div class="plus-xiala-item2-cont clearfix">
                                    <div class="plus-xiala-item2-cont-left fl"></div>
                                    <div class="plus-xiala-item2-cont-right fl">
                                        <p>
                                            <i class="iconfont icondui3"></i>呈现项目创业构想
                                        </p>
                                        <p>
                                            <i class="iconfont icondui3"></i>关注不同赛道得分<br>
                                            <i class="iconfont icondui3" style="opacity:0"></i>差异
                                        </p>
                                        <p>
                                            <i class="iconfont icondui3"></i>专家参与内容及<br>
                                            <i class="iconfont icondui3" style="opacity:0"></i>版式设计
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="plus-xiala-item2">
                                <h5>Word模板定制</h5>
                                <h6>为项目团队提供画册级BP计划书</h6>
                                <div class="plus-xiala-item2-cont clearfix">
                                    <div class="plus-xiala-item2-cont-left0 fl"></div>
                                    <div class="plus-xiala-item2-cont-right fl">
                                        <p>
                                            <i class="iconfont icondui3"></i>调研报告整理
                                        </p>
                                        <p>
                                            <i class="iconfont icondui3"></i>产品开发计划修订
                                        </p>
                                        <p>
                                            <i class="iconfont icondui3"></i>投资进入机制与<br>
                                            <i class="iconfont icondui3" style="opacity:0"></i>资本退出设计
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="plus-xiala-item2">
                                <h5>VCR制作</h5>
                                <h6>指定用途场景授权、定制拍摄</h6>
                                <div class="plus-xiala-item2-cont clearfix">
                                    <div class="plus-xiala-item2-cont-left1 fl"></div>
                                    <div class="plus-xiala-item2-cont-right fl">
                                        <p>
                                            <i class="iconfont icondui3"></i>提供多场景拍摄
                                        </p>
                                        <p>
                                            <i class="iconfont icondui3"></i>成片两个，1min和<br>
                                            <i class="iconfont icondui3" style="opacity:0"></i>3min
                                        </p>
                                        <p>
                                            <i class="iconfont icondui3"></i>深度挖掘团队视频<br>
                                            <i class="iconfont icondui3" style="opacity:0"></i>需求
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="plus-xiala-btn">
                            <img class="plus_img" src="./../assets/sjfw.png" alt="">
                            免费咨询定制方案
                        </div>
                    </div>
                </div>
                <div href="" v-show="this.token_num==1" class="fl link link-reg" @click="registered()" style="display:inline-block">登录</div>
                <div class="fl" v-show="this.token_num==2" @mouseover="mouseover()" @mouseout="mouseout()" style="display:inline-block">
                    <router-link :to="{name:'Personalcenter'}" >
                        <div class="header-z-img">
                            <div class="header-z-img_div">
                                <img :src="form.image" alt="">
                            </div>
                        </div>
                        <div class="header-login">
                            <span class="user-name">
                                {{form.name}}
                            </span>
                        </div>
                    </router-link>
                    <div class="m-account__btns account-popover" v-show="login_acc == true">
                        <div class="account-popover__header">
                            ID:{{form.name}}
                        </div>
                        <router-link :to="{name:'Personalcenter'}">
                            <div class="m_account_exit">
                                个人中心
                            </div>
                        </router-link> 
                        <div class="m_account_exit" style="border-bottom:none" @click="exit()">
                            退出登录
                        </div>
                    </div>
                </div> 
                <div class="btn-nav fl" @click="btn()" v-show="this.$route.name!='Detailspage' && this.$route.name!='indexPage'">
                    <i class="icon-menu"></i>
                </div>
            </div>
        </header>
        <div v-if="this.$route.name == 'indexPage'" class="indeBox">
            <router-view :key="key" @getMessage="showMsg"></router-view>
        </div>
        <div v-else class="container"
            :class="this.$route.matched[this.$route.matched.length-1].props.default.sidebar==true?'containers':''"
            :style="this.$route.name=='Contactus'?'padding-bottom:0px;min-height: calc(100vh - 206px);':''">
            <router-view :key="key" @getMessage="showMsg"></router-view>
        </div>
        <!-- 脚部 -->
        <div class="footer">
            <div class="ft-main ftw ft-index-block">
                <div class="footer_container">
                    <div class="footer_info">
                        <dl class="footer_info_column">
                            <router-link :to="{name:'indexPage'}">
                                <img src="./../assets/logo1.png" alt="" style="height:70px;margin-left:20px">
                            </router-link>
                        </dl>
                        <dl class="footer_info_column">
                            <a href="mailto:mia@ihago.com">
                                <dt>联系我们</dt>
                            </a>
                        </dl>
                        <!-- <dl class="footer_info_column">
                            <a href="https://www.ihago.com/" target="_blank">
                                <dt>关于我们</dt>
                            </a>
                        </dl> -->
                        <dl class="footer_info_column">
                            <router-link :to="{name:'Useragreement'}" target="_blank">
                                <dt>用户协议</dt>
                            </router-link>
                        </dl>
                        <dl class="footer_info_column">
                            <a href="mailto:mia@ihago.com">
                                <dt>加入我们</dt>
                            </a>
                        </dl>
                        <dl class="footer_info_column">
                            <a href="mailto:mia@ihago.com">
                                <dt>订制服务</dt>
                            </a>
                        </dl>
                    </div>
                </div>
            </div>
            <div class="footer_license ftw">
                <p>
                    <!-- <img src="https://static.ibeisai.com/icon/jinghui.ico" alt="" style="width:19px;margin-top:-4px;vertical-align: middle;"> &nbsp;&nbsp;&nbsp;
                    <a href="https://www.beian.gov.cn/portal/registerSystemInfo?recordcode=61019002001772" target="blank">
                        <span style="margin-right:15px">陕公网安备61019002001772号</span>
                    </a> -->
                    <a href="https://beian.miit.gov.cn/" target="blank">陕ICP备2021008899号   </a>
                </p>
                <p>素材库 V1.6.0 </p>
                <p>Copyright © 2024 课之一（西安）科技有限公司，All Rights Reserved.</p>
            </div>
        </div>
        <!-- 返回页顶 -->
        <div class="tool-bar">
            <a class="tb-item item-bt active" href="javascript:void(0)" @click="fankui()" title="反馈意见">
                <i class="iconfont iconfankuiyijian"></i>
            </a>
            <a class="tb-item item-bt active" href="javascript:void(0)" @click="Placedtop()" title="返回顶部">
                <i class="iconfont iconshangjiantou"></i>
            </a>
        </div>
        <BetterLogin :title="title" @childFn="parentFn"></BetterLogin>
        <div class="stPublic-win" v-show="infodisb==true">
            <div class="stPublic-mask"></div>
            <div class="stPublic-main">
                <div class="feedback-cont">
                    <div class="tit-top clearfix">
                        <h3 class="fl">欢迎对素材库提出宝贵的建议或意见</h3>
                        <span class="closeBtn fr el-icon-close" @click="closes('ruleForm')"></span>
                    </div>
                    <div class="form-mid">
                        <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="120px" class="demo-ruleForm" size="small">
                            <el-form-item label="建议意见:" prop="msg">
                                <el-input type="textarea" v-model="ruleForm.msg" :rows="5"></el-input>
                            </el-form-item>
                        </el-form>
                        <div class="subMit-btn" @click="sunmit('ruleForm')">
                            提交
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Search from '@/components/search/search.vue';
import { mapState } from 'vuex';
// import nav from '../store/moudels/nav';
// import nav from '../store/moudels/nav';
export default {
    components:{
        Search: Search
    },
    computed:{
        key(){
            return this.$route.path + Math.random()
        },
        ...mapState(['nav'])
    },
    data() {
        return {
            ruleForm:{
                msg:''
            },
            infodisb:false,
            // isElementVisible:true,
            key:0,
            rules:{
                msg:[
                    { required: true, message: '请输入意见内容'}
                ]
                // code:[
                //     { required: true, message: '请输入QQ号码'},
                //     {pattern:/^[1-9]\d{4,9}$/, message:'QQ格式不正确'}
                // ],
                // email:[
                //     { required: true, message: '邮箱不能为空'},
                //     { type: 'email', message: '请输入正确的邮箱地址', trigger: ['blur', 'change']}
                // ],
            },
            show:0,
            login_acc:false,
            token_num:1,
            form:{},
            keyword:'',
            title:'',
            select:''
        }
    },
    methods: {
        closes(formName){
            this.infodisb = false
            this.$refs[formName].resetFields();
        },
        fankui(){
            if(this.$store.state.token=='' || this.$store.state.token==undefined){
                this.title = true
            }else{
                this.infodisb = true
            }
        },
        showMsg(val){
           
            if(val==1){
                this.show = 0
            }
        },
        Placedtop(){
            document.getElementById("zhiding").scrollIntoView();
        },
        registered(){
            this.title = true
        },
        mouseover(){
            this.login_acc = true
        },
        mouseout(){
            this.login_acc = false
        },
        parentFn(payload){
            this.title = payload
        },
        keywordFn(key,val){
            this.keyword = key
            this.select = val
        },
        //退出
        exit(){
            localStorage.clear(); 
            location.reload();
        },
        location(){
            if(location.search!='' && this.$route.name=='indexPage'){
                this.axios.myinfo({
                    token:window.location.search.substring(1)
                }).then(res=>{
                    if(res.data.code==0){
                        this.$store.dispatch("token",res.data.data.token)
                        this.$store.dispatch("account",res.data.data.account)
                        this.$store.dispatch("avatar",res.data.data.avatar)
                        this.$store.dispatch("userName",res.data.data.name)
                        this.$store.dispatch("vip",res.data.data.vip)
                        this.$store.dispatch("vipName",res.data.data.vipName)
                        this.$store.dispatch("vipDate",res.data.data.vipDate)
                        this.$router.push({name:'index'})
                    }else{
                        return false
                    }
                }).catch(err=>{

                })
            }else{
                return false
            }
        },
        sunmit(formName){
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.axios.indexMsgadd({
                        token:this.$store.state.token,
                        msg:this.ruleForm.msg
                    }).then(res=>{
                        if(res.data.code==0){
                            this.infodisb = false
                            this.$message.success('提交成功')
                            this.$refs[formName].resetFields();
                        }else{

                        }
                    }).catch(err=>{

                    })
                }else{
                    return false
                }
            })
        },
        //判断token是否存在
        logintoken(){
            if(this.$store.state.token== '' || this.$store.state.token== undefined){
                this.token_num = 1
            }else{
                this.form.image = this.$store.state.avatar
                this.form.name = this.$store.state.userName
                this.token_num = 2
            }
        },
        btn(){
            if(this.show==0){
                this.show=1
            }else{
                this.show = 0
            }
        },
        // ...mapMutations(['UPP_BOOLE'])
    },
    watch:{
        '$store.state.avatar'(newVal){
            this.form.image = newVal;
            this.$forceUpdate();// 更新数据
        },
        '$store.state.userName'(newVal){
            this.form.name = newVal;
            this.$forceUpdate();// 更新数据
        }
    },
    mounted() {
        // this.UPP_BOOLE()
        // handleUpdateClick()
        // this.handleUpdateClick()
        this.location()
        this.$nextTick(function () {
            setTimeout(()=>{
                this.logintoken()
               
            },200)
        });        
    },

}
</script>
<style scoped>
@import "../styles/index.css";
</style>
<style>
.form-mid .el-textarea__inner{
    resize: none;
}
</style>